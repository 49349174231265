.p-inputtext {
  border: 0.9px #6600C9 solid !important;
  width: 100%;
  font-family: 'Lato', sans-serif !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0 !important;
}

.p-sidebar-right {
  width: 30vw;
}

.p-component {
  font-family: 'Lato', sans-serif !important;
}

.p-chips-input-token {
  width: 50%;
}

.add-job-form {
  .p-inputtext,
  .p-dropdown {
    margin: 6px 0;
  }

  .p-dropdown {
    height: 42px;
  }

  .p-chips-token {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-chips {
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
  }
  .p-editor-container {
    width: 100% !important;
    margin-top: 6px !important;
  }

  .p-chips-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow-y: auto !important;
    display: block !important;
    margin-top: 6px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #fb7c51af #ffffff;

    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fb7c51af; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    background: #fb7c51af !important;
  }
}

.invite_applicants_overlay_container {
  .p-chips-token {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-chips {
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
  }

  .p-chips-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow-y: auto !important;
    display: block !important;
    margin-top: 6px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #fb7c51af #ffffff;

    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fb7c51af; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    background: #fb7c51af !important;
  }
}

.p-inputtext:enabled:focus,
.p-inputnumber:enabled:focus,
.p-password:enabled:focus,
.p-dropdown:enabled:focus {
  box-shadow: 0 0 0 0.2rem #BC7AFF !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #BC7AFF !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #BC7AFF !important;
  border-color: #6600C9 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  box-shadow: 0 0 0 0.2rem #BC7AFF !important;
  border-color: #6600C9 !important;
}
.p-focus {
  box-shadow: 0 0 0 0.2rem #BC7AFF !important;
  border-color: #6600C9 !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #6600C9 !important;
}
.p-inputnumber {
  width: 100%;
}
.p-password {
  width: 100%;
}

.p-dropdown {
  border: 0.9px #6600C9 solid !important;
  width: 100% !important;
  font-family: 'Lato', sans-serif !important;
}

.p-dropdown .p-dropdown-label.p-inputtext {
  border: none !important;
}

// .p-dropdown-items-wrapper {
//   // background: #fb7c51 !important;
// }

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #6600C9 !important;
  background: #6600C9 !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #6600C9 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #6600C9 !important;
  background: #ffffff !important;
}

.p-float-label > label {
  font-size: 14px;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.9rem;
}

.p-dialog {
  border-radius: 8px !important;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.modal-with-footer {
  .p-dialog-content {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #EB249E #ffffff;
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(90deg, #A200CA -33.05%, #E200AA 145%); /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }
}

.modal-padding {
  .p-dialog .p-dialog-content {
    padding-bottom: 0px !important;
  }
}
.p-dialog-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #EB249E #ffffff;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #A200CA -33.05%, #E200AA 145%); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  // .p-dialog-mask.p-component-overlay {
  //   -webkit-backdrop-filter: blur(10px) !important;
  //   backdrop-filter: blur(10px) !important;
  //   background: linear-gradient(
  //     360.95deg,
  //     rgba(248, 247, 247, 0.6) -27.4%,
  //     rgba(255, 255, 255, 0.08) 58.08%
  //   ) !important;
  // }
  .p-dialog-mask.p-component-overlay {
    // background: rgba(255, 255, 255, 0.1) !important;
    background-color: rgba(9, 30, 66, 0.54) !important;
    transition: opacity 0.2s ease !important;
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .p-dialog-mask.p-component-overlay {
    background-color: rgba(9, 30, 66, 0.54) !important;
    transition: opacity 0.2s ease !important;
  }
}

// switch

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #6600C9 !important;
}

.p-inputswitch {
  width: 2.5rem !important;
  height: 1.4rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff !important;
  width: 1rem !important;
  height: 1rem !important;
  left: 0.25rem;
  margin-top: -0.5rem !important;
  border-radius: 50% !important;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}
.p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider:before {
  background: transparent !important;
  border: 2px solid #6600C9;
  width: 0.9rem !important;
  height: 0.9rem !important;
  left: 0.2rem;
  margin-top: -0.45rem !important;
  border-radius: 50% !important;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}
.p-inputswitch .p-inputswitch-slider {
  background: transparent !important;
  border: 2px solid #6600C9;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.9rem) !important;
}

.p-slider.p-slider-horizontal {
  height: 0.7rem !important;
}

.p-slider {
  background: #eaeaea !important;
  border: 0 none;
  border-radius: 0.7rem !important;
}

.p-slider .p-slider-handle {
  height: 1.8rem !important;
  width: 1.8rem !important;
  background: #6600C9 !important;
  cursor: pointer !important;
  border: 2px solid #6600C9 !important;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #6600C9 !important;
  border-color: #6600C9 !important;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.9rem !important;
  margin-left: -0.9rem !important;
}

.p-slider .p-slider-range {
  background: #c5c5c5 !important;
  border-radius: 0.7rem !important;
}

.searchFunctionality .sortIcon {
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-dropdown,
.searchFunctionality .p-dropdown .p-inputwrapper-focus {
  width: 100%;
  border-radius: 0 !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-dropdown,
.searchFunctionality .p-dropdown:focus,
.searchFunctionality .p-dropdown:hover,
.searchFunctionality .p-dropdown:active,
.searchFunctionality .p-dropdown .p-focus,
.searchFunctionality .p-dropdown .p-focus:focus,
.searchFunctionality .p-dropdown .p-focus:active,
.searchFunctionality .p-dropdown:not(.p-disabled).p-focus {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: 0px !important;
}

.searchFunctionality .p-inputtext {
  font-size: 0.8rem !important;
}

.searchFunctionality .p-inputtext {
  background-color: transparent !important;
  border-radius: 0 !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-inputtext:enabled:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.assignmentInput {
  .p-inputtextarea {
    background-color: #f8f8f8;
    border: none !important;
  }
  .p-inputtextarea:focus {
    box-shadow: none !important;
  }
}
.skillAssessmentTextarea {
  .p-inputtextarea {
    background-color: #f4f4f4 !important;
    border: none !important;
    resize: none !important;
  }
  .p-inputtextarea:focus {
    box-shadow: none !important;
  }
}

.emailDialog {
  .p-dialog-header {
    padding-bottom: 0px !important;
  }
}

.ql-container {
  font-size: 1rem !important;
}

.p-editor-container .p-editor-toolbar {
  background: #f4f4f4 !important;
  border-top-right-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border-top: 1px solid #6600C9 !important;
  border-right: 1px solid #6600C9 !important;
  border-left: 1px solid #6600C9 !important;
}
.p-editor-container .p-editor-content.ql-snow {
  border-bottom: 1px solid #6600C9 !important;
  border-right: 1px solid #6600C9 !important;
  border-left: 1px solid #6600C9 !important;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.p-progressbar .p-progressbar-value {
  background: #6600C9;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.5rem !important;
  color: #FF009C !important;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #FF009C !important;
  border-radius: 10px !important;
}

.p-datepicker table th > span {
  color: #FF009C !important;
  font-size: 0.8rem !important;
  width: 2rem;
  height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled {
  color: #FF009C !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #FF009C !important;
  border-color: transparent;
  background: #e9ecef;
}

.p-datepicker-calendar .p-datepicker-today > span:not(.p-highlight) {
  background: linear-gradient(97.11deg, #7A53BF -2.36%, #ED2C94 93.84%) !important;

  color: #ffffff !important;
}
.p-datepicker-calendar .p-highlight {
  background: linear-gradient(97.11deg, #7A53BF -2.36%, #ED2C94 93.84%) !important;

  color: #ffffff !important;
}

.p-datepicker table td > span {
  width: 1.6rem !important;
  height: 1.6rem !important;
  padding: 0.2rem !important;
  margin: 0.2rem 0 !important;
  color: #515151 !important;
}

.p-datepicker table td {
  font-size: 0.7rem !important;
  color: #515151 !important;
  padding: 0 !important;
}

.dashboard-date-picker {
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1.5rem !important;
    color: #FF009C !important;
  }

  .p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #FF009C !important;
    border: 2px solid #FF009C !important;
    border-radius: 10px !important;
  }

  .p-datepicker table th > span {
    color: #FF009C !important;
    font-size: 0.8rem !important;
    width: 2rem;
    height: 2rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled {
    color: #FF009C !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #FF009C !important;
    border-color: transparent;
    background: #e9ecef;
  }

  .p-datepicker-calendar .p-datepicker-today > span:not(.p-highlight) {
    background: linear-gradient(97.11deg, #7A53BF -2.36%, #ED2C94 93.84%) !important;

    color: #ffffff !important;
  }
  .p-datepicker-calendar .p-highlight {
    background: linear-gradient(97.11deg, #7A53BF -2.36%, #ED2C94 93.84%) !important;

    color: #ffffff !important;
  }

  .p-datepicker table td > span {
    width: 1.6rem !important;
    height: 1.6rem !important;
    padding: 0.2rem !important;
    margin: 0.2rem 0 !important;
    color: #515151 !important;
  }

  .p-datepicker table td {
    font-size: 0.7rem !important;
    color: #515151 !important;
    padding: 0 !important;
  }
}

.compare-applicants {
  .p-dialog .p-dialog-header {
    padding: 1rem !important;
    padding-bottom: 0rem !important;
    background-color: #fbf4e4 !important;
  }
}

.email-settings {
  .p-dropdown .p-dropdown-label:enabled:focus {
    box-shadow: none !important;
  }
}

.psy_report {
  .p-dialog-header {
    padding: 0 !important;
  }
}
