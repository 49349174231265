.react-tabs__tab-list {
  border: none !important;

  .react-tabs__tab--selected {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #a200ca -33.05%, #e200aa 145%);
  }

  .react-tabs__tab {
    white-space: nowrap !important;
    font-size: 17px !important;
    font-weight: 400;
    color: #2e3b4e;
  }

  .react-tabs__tab-panel {
    overflow-y: scroll;
  }

  .react-tabs__tab-panel--selected {
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #F591CF #ffffff;
    overflow-y: scroll !important;
  }
  .react-tabs__tab-panel--selected::-webkit-scrollbar {
    width: 7.5px;
  }

  .react-tabs__tab-panel--selected::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  .react-tabs__tab-panel--selected::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}
